export const isCampaignEnabledForVehicle = (activeCampaign: ActiveCampaign, vehicle: Vehicle) => {
  return activeCampaign?.enabled && vehicle.tags.includes(activeCampaign?.tag)
}

export const getCampaignImagesForVehicle = (activeCampaign: ActiveCampaign, vehicle: Vehicle) => {
  if (isCampaignEnabledForVehicle(activeCampaign, vehicle)) {
    return activeCampaign?.campaign?.images
  }

  return []
}

export const sortCampaigns = (campaigns: Campaign[]) => {
  /// NOTE: primary on top of array OR sort descending
  return campaigns?.sort((a, b) => {
    if (a.isPrimary && b.isPrimary) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    }

    if (a.isPrimary) {
      return -1
    }

    if (b.isPrimary) {
      return 1
    }

    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
}

const getActiveCampaignInternal = (campaigns: Campaign[]) => {
  const newCampaigns = sortCampaigns(campaigns)
  const campaign = newCampaigns?.find(item => item?.isPrimary) || campaigns?.[0]

  return (campaign && Object.keys(campaign).length) ? campaign : null
}

export const getActiveCampaign = (activeCampaigns: any) => {
  const config = useRuntimeConfig()

  const campaign = getActiveCampaignInternal(activeCampaigns)
  const enabled = campaign ? config.public.ENABLE_MARKETING_CAMPAIGN === 'true' : false
  const tag = enabled ? campaign?.id : null

  return {
    enabled,
    tag,
    campaign,
  }
}

export const applyCampaignToVehicleV2 = (vehicle: any, campaigns: Campaign[]) => {
  const sortedCampaigns = sortCampaigns(campaigns)

  let newVehicle = { ...vehicle }
  for (const campaign of sortedCampaigns) {
    if (!newVehicle?.tags?.includes(campaign.id)) {
      continue
    }

    newVehicle = {
      ...newVehicle,
      campaignId: campaign?.id,
      campaignImages: campaign?.images,
      campaignTexts: campaign?.texts,
    }

    break
  }

  return newVehicle
}
